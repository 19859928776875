import { ErrorMessage, useField } from 'formik';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

type Props = {
  fieldName: string;
};

export default function FileUploader({ fieldName }: Props) {
  const [files, setFiles] = useState<File[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, fieldMeta, helpers] = useField(fieldName);

  const totalSize = useMemo(() => {
    const totalSizeInBytes = Number(
      field?.value?.reduce((sum, { size = 0 }: File) => sum + size ?? 0, 0),
    );

    const megaBytes = totalSizeInBytes / 1024 / 1024;

    return megaBytes.toFixed(2);
  }, [field?.value]);

  useEffect(() => {
    helpers.setValue(files);
  }, [files]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e?.target?.files ?? [])]);
    }
  };

  const handleDelete = (file: File) => {
    setFiles(files?.filter((fileItem) => file !== fileItem));
  };

  return (
    <div>
      <div className="flex justify-center items-center w-full mt-4">
        <label
          htmlFor="dropzone-file"
          className={`flex flex-col justify-center items-center w-full h-50 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800  hover:bg-gray-100 ${
            files?.length === 3 ? `opacity-30` : ``
          }`}
        >
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="mb-3 w-10 h-10 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Klik om the uploaden</span> of
              sleep de bestanden in dit vak
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG of PDF (max 3 bestanden ({totalSize}MB / 60MB))
            </p>
          </div>
          <input
            disabled={files?.length === 3}
            id="dropzone-file"
            type="file"
            className="hidden"
            accept=".jpg,.jpeg,.png,.svg,.pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <div className="mt-4">
        {field?.value?.map((file) => (
          <div className="h-10 flex px-5 rounded-xl mb-2 items-center bg-neutral-200 border-sm justify-between">
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>

              <p className="ml-2"> {file?.name}</p>
            </div>
            <svg
              onClick={() => handleDelete(file)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 hover:scale-110"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>
        ))}
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg: string) => (
          <div className="text-sm text-red-400">{msg}</div>
        )}
      />
    </div>
  );
}
