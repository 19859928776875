import { ErrorMessage, Field } from 'formik';
import React from 'react';

type Props = {
  identifier: string;
  label: string;
};

export default function TextInput({ identifier, label }: Props) {
  return (
    <>
      <label
        htmlFor={identifier}
        className="block text-sm font-medium text-gray-600"
      >
        {label}
      </label>
      <Field
        name={identifier}
        type="text"
        className="max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-gray-200 rounded"
      />
      <ErrorMessage
        name={identifier}
        render={(msg: string) => (
          <div className="text-sm text-red-400">{msg}</div>
        )}
      />
    </>
  );
}
