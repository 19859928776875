import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { encode } from 'querystring';
import TextInput from '@/components/form/TextInput';
import * as yup from 'yup';
import Loader from '@/components/loader/Loader';
import { Content } from '@/components';
import SuccessCardSponsor from '@/components/form/SuccessCardSponsor';
import FileUploader from '@/components/fileupload/FileUploader';
import AmountInput from '@/components/form/AmountInput';
import SponsorPaketten from './sponsorpaketten';

const validationScheme = yup.object().shape({
  firstname: yup.string().required(`Verplicht`),
  lastname: yup.string().required(`Verplicht`),
  email: yup.string().email(`Geen geldig email adres`).required(`Verplicht`),
  street: yup.string().required(`Verplicht`),
  postalcode: yup.string().required(`Verplicht`),
  city: yup.string().required(`Verplicht`),
  package: yup.number().min(0, `Selecteer een sponsor pakket`),
  amount: yup
    .number()
    // .min(75, `Bedrag moet hoger zijn dan €75`)
    .when(`package`, {
      is: 1,
      then: (schema) =>
        schema
          .required(`Vul een sponsorbedrag in`)
          .min(76, `Bedrag moet hoger zijn dan €75`),
    }),
  invoice: yup.boolean(),
  files: yup
    .array()
    .test(
      `fileSize`,
      `Bijlages zijn groter dan 60MB. Verwijder een of meerdere bijlages`,
      (value) =>
        Number(value?.reduce((sum, { size = 0 }: File) => sum + size, 0)) /
          1024 /
          1024 <
        60,
    ),
});

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  street: string;
  postalcode: string;
  city: string;
  package: number;
  amount: string;
  invoice: boolean;
  files?: File[];
}

export default function inschrijfformuliersponsoren() {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailAddres, setEmailAddress] = useState(``);

  const sendEmailConfirmation = async (values: FormValues) => {
    const formData = new FormData();

    values?.files?.forEach((file) => {
      formData.append(`files`, file);
    });

    Object.keys(values)?.forEach((key) => {
      formData.append(key, values[key]);
    });

    // if user has entered a custom amount, use that instead of the default
    formData.delete(`amount`);
    formData.append(`amount`, values?.amount ? values.amount : `75`);

    console.log(formData);

    await fetch(
      `https://mobiletraction-mailservice.herokuapp.com/mailer/sendmail`,
      {
        method: `POST`,
        body: formData,
        headers: {
          Authorization: `aG39SHjU0DmdwiGSZkT5QyJkWbM5KNcgV4jMHA3zAtTE528DfWCeEiT8SVK0`,
          // 'content-length': `${files.size}`, // 👈 Headers need to be a string
        },
      },
    );
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      setEmailAddress(values.email);
      sendEmailConfirmation(values);

      await fetch(`/?no-cache=1`, {
        method: `POST`,
        headers: { 'Content-Type': `application/x-www-form-urlencoded` },
        body: encode({
          'form-name': `inschrijf-formulier-sponsor`,
          firstname: values?.firstname,
          lastname: values?.lastname,
          email: values?.email,
          amount: values?.amount,
          street: values?.street,
          postalcode: values?.postalcode,
          city: values?.city,
          invoice: values?.invoice,
        }),
      });

      setShowSuccess(true);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content>
      {showSuccess ? (
        <SuccessCardSponsor email={emailAddres} />
      ) : (
        <Formik
          validationSchema={validationScheme}
          initialValues={{
            firstname: ``,
            lastname: ``,
            email: ``,
            city: ``,
            street: ``,
            postalcode: ``,
            package: -1,
            invoice: true,
            files: [],
          }}
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue, submitCount }) => (
            <Form data-netlify name="inschrijf-formulier-sponsor">
              <div>
                <div className="pt-10 sm:pt-4 ">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-primary">
                          Bedrijfsinformatie
                        </h3>
                        <p className="mt-1 text-sm text-primary">
                          Vul hier alle informatie over uw bedrijf in.
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow-md overflow-hidden sm:rounded-md bg-background relative">
                        <Loader loading={loading}>
                          <div className="px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-3">
                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="firstname"
                                  label="Voornaam"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="lastname"
                                  label="Achternaam"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="company-name"
                                  label="Bedrijfsnaam"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="email"
                                  label="Emailadres"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="phone-number"
                                  label="Telefoonnummer"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="street"
                                  label="Straat en huisnummer"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <TextInput
                                  identifier="postalcode"
                                  label="Postcode"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <TextInput
                                  identifier="city"
                                  label="Woonplaats"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <TextInput
                                  identifier="kvk"
                                  label="KVK nummer (optioneel)"
                                />
                              </div>
                            </div>
                          </div>
                        </Loader>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-primary">
                          Sponsordetails
                        </h3>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2 ">
                      <div className="shadow overflow-hidden sm:rounded-md bg-background relative z-10">
                        <Loader loading={loading}>
                          <div className="px-4 py-5">
                            <label className="text-base font-medium text-gray-600">
                              Sponsorpakket
                            </label>
                            <SponsorPaketten
                              activeIndex={values?.package}
                              onPressPackage={(index) =>
                                setFieldValue(`package`, index)
                              }
                            >
                              <div>
                                <div className="mt-4 flex items-center justify-center">
                                  <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                                    <span className="mt-2 mr-2 text-4xl font-medium">
                                      €
                                    </span>
                                    {/* <span className="font-extrabold">75,-</span> */}
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                      <AmountInput identifier="amount" />
                                      {errors.amount && submitCount > 0 && (
                                        <div className="text-sm text-red-400">
                                          {errors.amount}
                                        </div>
                                      )}
                                      {/* <input
                            type="text"
                            name="price"
                            id="price"
                            className="block rounded-md border-0 text-xl h-20 w-32  py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                          /> */}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </SponsorPaketten>
                            <ErrorMessage
                              name="package"
                              render={(msg: string) => (
                                <div className="text-sm text-red-400">
                                  {msg}
                                </div>
                              )}
                            />
                            <div className="grid grid-cols-1 gap-6 py-3">
                              <div>
                                <legend className="sr-only">
                                  Sponsor pakket
                                </legend>
                              </div>
                              <div className="sm:border-t sm:border-gray-200 py-5">
                                <label className="text-base font-medium text-gray-600 mb-4">
                                  Upload hier je bedrijfslogo
                                </label>
                                <p className="mt-2 text-sm text-primary">
                                  Heb je afgelopen jaren ook gesponsord? Dan
                                  hebben we je logo nog.
                                </p>
                                <FileUploader fieldName="files" />
                              </div>
                              <div className="sm:border-t sm:border-gray-200 py-5">
                                <label className="text-base font-medium text-gray-600">
                                  Betaalgegevens
                                </label>
                                <div className="relative flex items-start mt-4">
                                  <div className="text-sm">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-700"
                                    >
                                      <Field
                                        type="checkbox"
                                        name="invoice"
                                        className="focus:ring-primary h-4 w-4 text-primary border-gray-300 mr-4"
                                      />
                                      Ik ontvang graag een factuur
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Loader>

                        <div className="px-4 py-3  text-right sm:px-6 bg-neutral-200">
                          <button
                            disabled={loading}
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary bg-secondary hover:color-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Versturen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Content>
  );
}
