import { Field } from 'formik';
import React from 'react';

type Props = {
  identifier: string;
};

export default function AmountInput({ identifier }: Props) {
  return (
    <>
      <Field
        name={identifier}
        min
        type="number"
        className="max-w-lg block w-full shadow-sm focus:ring-primary focus:border-primary sm:max-w-xs sm:text-sm border-gray-200 rounded"
      />
      {/* <ErrorMessage
        name={identifier}
        render={(msg: string) => (
          <div className="text-sm text-red-400">{msg}</div>
        )}
      /> */}
    </>
  );
}
