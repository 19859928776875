import React from 'react';
import CVImage from '../../assets/cv.jpeg';

interface Props {
  email: string;
}

export default function SuccessCardSponsor({ email = `` }: Props) {
  return (
    <div className="bg-background shadow-2xl rounded-lg flex-row flex self-center lg:mt-20">
      <div className="w-full lg:w-1/2 p-12 flex items-center justify-center flex-col">
        <div className="text-third text-xl font-bold mb-6">
          Bedankt voor je sponsoring!
        </div>
        <span className="text-base font-normal">
          {`Een bevestigingsmail is verzonden naar: ${email}. Volg de instructies in deze mail de sponsoring definitief te maken`}
        </span>
        {/* <div className="text-base font-semibold text-primary mt-6">
          We zien jullie graag op 
        </div> */}
      </div>
      <div className="hidden lg:block lg:w-1/2 ">
        <img
          alt="CV de Boschule"
          src={CVImage}
          className="object-fill lg:w-auto rounded-lg"
        />
      </div>
    </div>
  );
}
